import React, { useState, useContext } from "react";
// import CardBox from "components/CardBox";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import Grid from "@material-ui/core/Grid";
// import { MyContext } from "components/RemExpert/RemExprertProvider";
// import Button from "@material-ui/core/Button";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import { AppBar } from "@material-ui/core";
// import PropTypes from "prop-types";
// import { NavLink } from "react-router-dom";
import { GetListeDossier, GetUserClients, GetUsersList } from "../Api/ApiAdmin";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { AppContext } from "components/app/RemExprertProvider";
import { Box, Button, List, ListItem, ListItemText, Tab, Tabs, TextField, Typography } from "@mui/material";
// function TabContainer(props) {
//   return <div style={{ padding: 20 }}>{props.children}</div>;
// }

// TabContainer.propTypes = {
//   children: PropTypes.node.isRequired
// };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function AdminDossier(props) {
  const [users, setUsers] = useState([]);
  const [idUser, setidUser] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [userClients, setuserClients] = useState([]);
  const [clientDossiers, setclientDossiers] = useState([]);
  const [search, setSearch] = useState("");
  const context = useContext(AppContext);
  let Time = 0;

  const searchUsers = (event) => {
    const Search = event.target.value;
    if (Time) clearTimeout(Time);
    Time = setTimeout(() => {
      GetUsersList(context.state.IdUser, Search).then((res) => {
        setUsers(res);
        setuserClients([]);
      });
    }, 750);
  };

  const DisplayUser = (IdUser) => {
    setidUser(IdUser);
    GetUserClients(context.state.IdUser, IdUser).then((res) => {
      setuserClients(res);
      setUsers([]);
      context.handleValueChange("ListeClient", res);
      context.handleValueChange("AdminAccess", true);
    });
  };

  const handleTabChange = (event, value) => {
    setTabIndex(value);
  };
  const AccessDossier = (IdUser, IdClient) => {
    GetListeDossier(context.state.IdUser, IdUser, IdClient).then((res) => {
      context.handleDisplaySimulation(IdClient);
      context.handleValueChange("ListeDossier", res);
      setclientDossiers(res);
      // setuserClients([]);
    });
  };
  // const TransfertDossier = (IdUserToTransfert, IdClient, IdDossier) => {
  //   SetTransfertDossier(context.state.IdUser, IdUserToTransfert, IdClient, IdDossier).then((res) => {
  //     // context.handleDisplaySimulation(IdClient);
  //     // context.handleValueChange("ListeDossier", res);
  //     // setclientDossiers(res);
  //     // setuserClients([]);
  //   });
  // };
  return (
    <Column fill style={{ maxWidth: "1000px" }}>
      <CardBox>
        <Column>
          <Row center>
            <Column>
              <TextField
                size="small"
                label="Rechercher un utilisateur"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  searchUsers(e);
                }}
                normal
              />
            </Column>
          </Row>
        </Column>
      </CardBox>
      {users.length > 0 && (
        <CardBox>
          <>
            <List dense={true}>
              {users.map((user) => (
                <ListItem button onClick={() => DisplayUser(user.IdUser)}>
                  <ListItemText primary={user.IdUser + " " + user.Prenom + " " + user.Nom} />
                </ListItem>
              ))}
            </List>
          </>
        </CardBox>
      )}
      {userClients.length > 0 && (
        <CardBox style={{ overflow: "auto", maxHeight: "500px" }} tpad>
          <Column scroll fill>
            <table>
              <thead>
                <tr>
                  <th>Date de création</th>
                  <th>Nom</th>
                  <th>Prénom </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userClients.map((Infos, index) => (
                  <tr key={"Client" + index}>
                    <td>{Infos.DateAdd}</td>
                    <td>{Infos.Nom}</td>
                    <td>{Infos.Prenom}</td>
                    <td>
                      <Button variant="contained" size="small" onClick={() => AccessDossier(idUser, Infos.IdClient)}>
                        <span>Accès</span>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Column>
        </CardBox>
      )}
      {clientDossiers?.length > 0 && (
        <CardBox style={{ overflow: "auto", minHeight: "250px" }} tpad>
          <Column >
              <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary" orientation="vertical" centered scrollButtons="auto">
                <Tab label={"Audit complet (" + clientDossiers.filter((Client) => Client.TypeSimulation === "Audit complet").length + ")"} />
                <Tab label={"Audit retraite (" + clientDossiers.filter((Client) => Client.TypeSimulation === "Audit retraite").length + ")"} />
                <Tab label={"Audit prévoyance (" + clientDossiers.filter((Client) => Client.TypeSimulation === "Audit prévoyance").length + ")"} />
                <Tab label={"Audit rémunération  (" + clientDossiers.filter((Client) => Client.TypeSimulation === "Audit rémunération").length + ")"} />
                <Tab label={"Calculatrice (" + clientDossiers.filter((Client) => Client.TypeSimulation === "Calculatrice AGIRC ARRCO").length + ")"} />
                <Tab label={"Déclaration PER (" + clientDossiers.filter((Client) => Client.TypeSimulation === "Déclaration PER").length + ")"} />
                <Tab label={"Correction PER (" + clientDossiers.filter((Client) => Client.TypeSimulation === "Correction plafond PER").length + ")"} />
                <Tab label={"Stratégie PER (" + clientDossiers.filter((Client) => Client.TypeSimulation === "Stratégie PER").length + ")"} />
              </Tabs>
              </Column>
              <Column fill>
                <TabPanel value={tabIndex} index={0}>
                  <table>
                    <thead>
                      <tr>
                        <th>Date de création</th>
                        <th>Libellé</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDossiers
                        .filter((Client) => Client.TypeSimulation === "Audit complet")
                        .map((Infos, index) => (
                          <tr key={"Dossier" + index}>
                            <td>{Infos.DateAdd}</td>
                            <td>{Infos.Libelle}</td>
                            <td>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  props.AccessDossier();
                                  context.handleOpenDossier(Infos.IdDossier);
                                }}
                              >
                                <span>Accès</span>
                              </Button>
                            </td>
                            <td>
                              {/* <Button
                                          variant="contained"
                                          className="jr-btn jr-btn-sm bg-blueRemExpert text-white "
                                          onClick={() => TransfertDossier(1008, Infos.IdClient, Infos.IdDossier)}
                                        >
                                          <span>Transfert</span>
                                        </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Date de création</th>
                        <th scope="col">Libellé</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDossiers
                        .filter((Client) => Client.TypeSimulation === "Audit retraite")
                        .map((Infos, index) => (
                          <tr key={"Dossier" + index}>
                            <td>{Infos.DateAdd}</td>
                            <td>{Infos.Libelle}</td>
                            <td>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  props.AccessDossier();
                                  context.handleOpenDossier(Infos.IdDossier);
                                }}
                              >
                                <span>Accès</span>
                              </Button>
                            </td>
                            <td>
                              {/* <Button variant="contained" className="jr-btn jr-btn-sm bg-orange text-white " onClick={() => TransfertDossier(1008, Infos.IdClient, Infos.IdDossier)}>
                                  <span>Transfert</span>
                                </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <table>
                    <thead>
                      <tr>
                        <th>Date de création</th>
                        <th>Libellé</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDossiers
                        .filter((Client) => Client.TypeSimulation === "Audit prévoyance")
                        .map((Infos, index) => (
                          <tr key={"Dossier" + index}>
                            <td>{Infos.DateAdd}</td>
                            <td>{Infos.Libelle}</td>
                            <td>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  props.AccessDossier();
                                  context.handleOpenDossier(Infos.IdDossier);
                                }}
                              >
                                <span>Accès</span>
                              </Button>
                            </td>
                            <td>
                              {/* <Button
                                          variant="contained"
                                          className="jr-btn jr-btn-sm bg-indigo text-white "
                                          onClick={() => TransfertDossier(1008, Infos.IdClient, Infos.IdDossier)}
                                        >
                                          <span>Transfert</span>
                                        </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>

                <TabPanel value={tabIndex} index={3}>
                  <table className="project-list-table table remove-table-border mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Date de création</th>
                        <th scope="col">Libellé</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDossiers
                        .filter((Client) => Client.TypeSimulation === "Audit rémunération")
                        .map((Infos, index) => (
                          <tr key={"Dossier" + index}>
                            <td>{Infos.DateAdd}</td>
                            <td>{Infos.Libelle}</td>
                            <td>
                              <Button
                                variant="contained"
                                className="jr-btn jr-btn-sm bg-red text-white "
                                onClick={() => {
                                  props.AccessDossier();
                                  context.handleOpenDossier(Infos.IdDossier);
                                }}
                              >
                                <span>Accès</span>
                              </Button>
                            </td>
                            <td>
                              {/* <Button variant="contained" className="jr-btn jr-btn-sm bg-red text-white " onClick={() => TransfertDossier(1008, Infos.IdClient, Infos.IdDossier)}> */}
                              {/* <span>Transfert</span>
                                </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>

             
                <TabPanel value={tabIndex} index={4}>
                  <table className="project-list-table table remove-table-border mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Date de création</th>
                        <th scope="col">Libellé</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDossiers
                        .filter((Client) => Client.TypeSimulation === "Calculatrice AGIRC ARRCO")
                        .map((Infos, index) => (
                          <tr key={"Dossier" + index}>
                            <td>{Infos.DateAdd}</td>
                            <td>{Infos.Libelle}</td>
                            <td>
                              <Button
                                variant="contained"
                                className="jr-btn jr-btn-sm bg-indigo text-white "
                                onClick={() => {
                                  props.AccessDossier();
                                  context.handleOpenDossier(Infos.IdDossier);
                                }}
                              >
                                <span>Accès</span>
                              </Button>
                            </td>
                            <td>
                              {/* <Button variant="contained" className="jr-btn jr-btn-sm bg-indigo text-white " onClick={() => TransfertDossier(1008, Infos.IdClient, Infos.IdDossier)}>
                                  <span>Transfert</span>
                                </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value={tabIndex} index={5}>
                  <table className="project-list-table table remove-table-border mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Date de création</th>
                        <th scope="col">Libellé</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDossiers
                        .filter((Client) => Client.TypeSimulation === "Déclaration PER")
                        .map((Infos, index) => (
                          <tr key={"Dossier" + index}>
                            <td>{Infos.DateAdd}</td>
                            <td>{Infos.Libelle}</td>
                            <td>
                              <Button
                                variant="contained"
                                className="jr-btn jr-btn-sm bg-indigo text-white "
                                onClick={() => {
                                  props.AccessDossier();
                                  context.handleOpenDossier(Infos.IdDossier);
                                }}
                              >
                                <span>Accès</span>
                              </Button>
                            </td>
                            <td>
                              {/* <Button variant="contained" className="jr-btn jr-btn-sm bg-indigo text-white " onClick={() => TransfertDossier(1008, Infos.IdClient, Infos.IdDossier)}>
                                  <span>Transfert</span>
                                </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value={tabIndex} index={6}>
                  <table className="project-list-table table remove-table-border mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Date de création</th>
                        <th scope="col">Libellé</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDossiers
                        .filter((Client) => Client.TypeSimulation === "Correction plafond PER")
                        .map((Infos, index) => (
                          <tr key={"Dossier" + index}>
                            <td>{Infos.DateAdd}</td>
                            <td>{Infos.Libelle}</td>
                            <td>
                              <Button
                                variant="contained"
                                className="jr-btn jr-btn-sm bg-indigo text-white "
                                onClick={() => {
                                  props.AccessDossier();
                                  context.handleOpenDossier(Infos.IdDossier);
                                }}
                              >
                                <span>Accès</span>
                              </Button>
                            </td>
                            <td>
                              {/* <Button variant="contained" className="jr-btn jr-btn-sm bg-indigo text-white " onClick={() => TransfertDossier(1008, Infos.IdClient, Infos.IdDossier)}>
                                  <span>Transfert</span>
                                </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value={tabIndex} index={7}>
                  <table className="project-list-table table remove-table-border mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Date de création</th>
                        <th scope="col">Libellé</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDossiers
                        .filter((Client) => Client.TypeSimulation === "Stratégie PER")
                        .map((Infos, index) => (
                          <tr key={"Dossier" + index}>
                            <td>{Infos.DateAdd}</td>
                            <td>{Infos.Libelle}</td>
                            <td>
                              <Button
                                variant="contained"
                                className="jr-btn jr-btn-sm bg-indigo text-white "
                                onClick={() => {
                                  props.AccessDossier();
                                  context.handleOpenDossier(Infos.IdDossier);
                                }}
                              >
                                <span>Accès</span>
                              </Button>
                            </td>
                            <td>
                              {/* <Button variant="contained" className="jr-btn jr-btn-sm bg-indigo text-white " onClick={() => TransfertDossier(1008, Infos.IdClient, Infos.IdDossier)}>
                                  <span>Transfert</span>
                                </Button> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>
              </Column>
          
        </CardBox>
      )}
    </Column>
  );
}

export default AdminDossier;
